.main-image {
    max-height: 350px;
    /*width: 300px;*/
    /*clip-path: circle();*/
    border-radius: 15px; /* Adjust the value to control the roundness */
    filter: drop-shadow(1px 1px 5px hsl(0, 0%, 75%));
}

@media (min-width: 800px) {
    .main-image {
        max-height: 500px;
        /*width: 500px;*/
    }
}

/* Add a class for the colored link */
.color-link {
    color: #336dff; /* or your preferred color code */
}