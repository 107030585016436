body {
  font-family: 'Open Sans', sans-serif !important;
}

.section-grid {
  max-width: 1248px;
}

.section-logo {
  width: 250px;
  height: auto;
}

a {
  text-decoration: none !important;
  color: inherit;
  /*color: inherit !important;*/
}

.clickable {
  transition: 0.05s ease-in-out;
}

.clickable:hover {
  transform: translate(-1px, -1px) scale(1.025);
}

.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

