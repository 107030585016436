.custom-nav-link {
    color: unset;
    text-decoration: none;
}

.custom-nav-link:hover {
    /*filter: brightness(250%);*/
}

@media (max-width: 800px) {
    .h4 {
        font-size: 1.125rem !important;
    }
}